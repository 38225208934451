<template>
  <div id="projects">
    <section class="max-w-6xl mx-auto p-8">
      <h2 class="text-3xl mb-4">things that i made</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <a referrerpolicy="no-referrer" href="https://movies.samj.app/">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">SamJ Movies</h3>
            <p class="text-sm">watch any movie with no ads for free</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://sports.samj.app/">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">SamJ Sports</h3>
            <p class="text-sm">watch any sports for free with no ads</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://games.samjhanachhetri.com.np/">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">SamJ Games (Unblocked 11/3)</h3>
            <p class="text-sm">games site with over 2000 games</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://books.samj.app/">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">SamJ Books</h3>
            <p class="text-sm">free books (fixed)</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://videos.samj.app/">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">SamJ Videos/Music</h3>
            <p class="text-sm">watch youtube, listen to music</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://educake.samj.app/">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">EduBait</h3>
            <p class="text-sm">hack that gets all the educake answers</p>
          </div>
        </a>
        <!-- Random project will be inserted here -->
        <a referrerpolicy="no-referrer" id="random-project-link" href="#" style="display:none;">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2" id="random-project-title"></h3>
            <p class="text-sm" id="random-project-description"></p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://everything.samj.app">
          <div class="bg-neutral-950 p-4 rounded-xl shadow-lg hover:bg-neutral-800 transition duration-300 text-white">
            <h3 class="text-xl font-semibold mb-2">everything else</h3>
            <p class="text-sm">everything else i've ever made</p>
          </div>
        </a>
      </div>
    </section>
  </div>
  <div id="connect">
    <section class="max-w-6xl mx-auto p-8">
      <h2 class="text-3xl mb-4">stalk me</h2>
      <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <a referrerpolicy="no-referrer" href="https://message.samj.app">
            <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-neutral-800 transition duration-300 text-white w-full">
              <i class="fa-solid fa-envelope fa-lg"></i> Send me a message
            </button>
          </a>
        </div>
        <div>
          <a referrerpolicy="no-referrer" href="https://chat.samj.app">
            <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-neutral-800 transition duration-300 text-white w-full">
              <i class="fa-solid fa-comment fa-lg"></i> SamJ Chat
            </button>
          </a>
        </div>
        <div>
          <a referrerpolicy="no-referrer" href="https://github.com/SamJ2104" target="_blank">
            <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-neutral-800 transition duration-300 text-white w-full">
              <i class="fab fa-github fa-lg"></i> GitHub
            </button>
          </a>
        </div>
        <div>
          <a referrerpolicy="no-referrer" href="https://tiktok.com/@_samjam_" target="_blank">
            <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-neutral-800 transition duration-300 text-white w-full">
              <i class="fa-brands fa-tiktok fa-lg"></i> TikTok
            </button>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM fully loaded');
  
  function initializeRandomProject() {
    const randomProjectLink = document.getElementById('random-project-link');

    if (!randomProjectLink) {
      console.warn('random-project-link element not found, retrying in 500ms...');
      setTimeout(initializeRandomProject, 50);
      return;
    }

    const randomProjectTitle = document.getElementById('random-project-title');
    const randomProjectDescription = document.getElementById('random-project-description');

    if (!randomProjectTitle || !randomProjectDescription) {
      console.error('Title or description element not found');
      return;
    }

    const projects = [
      {
        "title": "Screen Time Phisher",
        "description": "a tool to phish the screen time password",
        "url": "http://screen-time.samj.app"
      },
      {
        "title": "You Might Like",
        "description": "AI tool that gives you recomendations",
        "url": "http://youmightlike.samj.app"
      },
      {
        "title": "AI vs The Trolley Problem",
        "description": "test the morality of an ai with the trolley problem",
        "url": "http://trolley.samj.app"
      },
      {
        "title": "Hwb Password Game",
        "description": "password game with hwb ui",
        "url": "http://samj2104.github.io/HwbPasswordGame"
      },
      {
        "title": "Not Google Classroom",
        "description": "google classroom but has samj.app embedded",
        "url": "http://classroom.samj.app"
      },
      {
        "title": "Translate",
        "description": "Unreliable translate tool",
        "url": "http://translate.samj.app"
      },
      {
        "title": "Wordle Tommorow",
        "description": "A game of worldle powered by crystal Balls",
        "url": "http://wordle.samj.app"
      },
      {
        "title": "Flags Game",
        "description": "Guess what the flag is",
        "url": "http://flags.samj.app"
      },
      {
        "title": "Five Fingers",
        "description": "fake coupons app",
        "url": "http://fivefingers.samj.app"
      },
      {
        "title": "Rigged Dice",
        "description": "However many times you tap thats the result",
        "url": "http://dice.samj.app"
      },
      {
        "title": "SwitchQuip",
        "description": "SwitchQuip encoding, undecodable by computers",
        "url": "http://switchquip.samj.app"
      },
      {
        "title": "Message Me",
        "description": "Send a message to me",
        "url": "http://message.samj.app"
      },
      {
        "title": "Lessons",
        "description": "What Lessons I Have",
        "url": "http://lessons.samj.app"
      },
      {
        "title": "Masker",
        "description": "Masks urls to look more official",
        "url": "http://masker.samj.app"
      },
      {
        "title": "URL Shortener",
        "description": "URL shortener tool",
        "url": "http://url.samj.app"
      },
      {
        "title": "URL Masker",
        "description": "has a wildcard domain and shows links in an iframe",
        "url": "http://anythinghere.00.com.se"
      },
      {
        "title": "Shady URL",
        "description": "Makes a url look dodgey",
        "url": "http://shady-url.samj.app"
      },
      {
        "title": "Coin Flip",
        "description": "Rigged Coin flip tool",
        "url": "http://coinflip.samj.app"
      },
      {
        "title": "Todays Mission",
        "description": "Gives you a random mission",
        "url": "http://mission.samj.app"
      },
      {
        "title": "AITA",
        "description": "Am I The Asshole Game",
        "url": "http://aita.samj.app"
      },
      {
        "title": "Do It",
        "description": "ToDo site, made in 10 minutes",
        "url": "http://doit.samj.app"
      },
    ];

    const randomProject = projects[Math.floor(Math.random() * projects.length)];

    console.log('Random Project:', randomProject);

    randomProjectLink.style.display = 'block';
    randomProjectTitle.innerText = randomProject.title;
    randomProjectDescription.innerText = randomProject.description;
    randomProjectLink.href = randomProject.url;
  }

  initializeRandomProject();
});

</script>
